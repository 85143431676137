import { Analyzer } from "~/repositories/analyzer";
import { Character } from "~/repositories/character";
import { defaultConsumable } from "~/repositories/consumable";
import { defaultImage } from "~/repositories/image";
import { defaultMember, Member } from "~/repositories/member";
import { defaultMob } from "~/repositories/mob";
import { defaultModifierList } from "~/repositories/modifier_list";
import { Monster } from "~/repositories/monster";
import { defaultPet } from "~/repositories/pet";
import { defaultSkill } from "~/repositories/skill";
import { defaultStatistics } from "~/repositories/statistics";
import { skillSequenceList } from "~/routes/(app)/(functionPage)/analyzer/(analyzer)";

export const test: {
  member: Member;
  monster: Monster;
  analyzer: Analyzer;
  skillSequence1: skillSequenceList;
  skillSequence2: skillSequenceList;
} = {
  member: {
    id: "",
    character: {
      id: "",
      characterType: "Tank",
      name: "测试机体",
      lv: 265,
      baseStr: 1,
      baseInt: 440,
      baseVit: 1,
      baseAgi: 1,
      baseDex: 247,
      specialAbiType: "NOSPECIALABI",
      specialAbiValue: 0,
      mainWeapon: {
        id: "",
        name: "暴击残酷之翼",
        mainWeaponType: "MAGIC_DEVICE",
        baseAtk: 194,
        refinement: 15,
        stability: 70,
        element: "LIGHT",
        crystalList: [
          {
            id: "",
            name: "寄生甲兽",
            crystalType: "WEAPONCRYSTAL",
            front: 0,
            modifierList: {
              id: "",
              name: "寄生甲兽",
              modifiers: [
                {
                  id: "",
                  formula: "mAtk + 5%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "mPie + 20",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "cspd - 15%",
                  belongToModifierListId: "",
                },
              ],
            },
            modifierListId: "",
            createdAt: new Date(),
            createdByUserId: "",
            updatedAt: new Date(),
            updatedByUserId: "",
            extraDetails: "",
            dataSources: "",
            statistics: defaultStatistics,
            statisticsId: "",
          },
          {
            id: "",
            name: "死灵妖兔II",
            crystalType: "WEAPONCRYSTAL",
            front: 1,
            modifierList: {
              id: "",
              name: "死灵妖兔II",
              modifiers: [
                {
                  id: "",
                  formula: "mAtk + 7%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "cspd + 14%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "maxHp - 15%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "am + 3",
                  belongToModifierListId: "",
                },
              ],
            },
            modifierListId: "",
            createdAt: new Date(),
            createdByUserId: "",
            updatedAt: new Date(),
            updatedByUserId: "",
            extraDetails: "",
            dataSources: "",
            statistics: defaultStatistics,
            statisticsId: "",
          },
        ],
        modifierList: {
          id: "",
          name: "暴击残酷之翼属性",
          modifiers: [
            {
              id: "",
              formula: "mAtk + 6%",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "pCr + 25",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "pCd + 21",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "stro.DARK + 21",
              belongToModifierListId: "",
            },
          ],
        },
        modifierListId: "",
        createdAt: new Date(),
        createdByUserId: "",
        updatedAt: new Date(),
        updatedByUserId: "",
        extraDetails: "",
        dataSources: "",
        statistics: defaultStatistics,
        statisticsId: "",
      },
      mainWeaponId: "",
      subWeapon: {
        id: "",
        name: "忍术卷轴·风遁术",
        subWeaponType: "NO_WEAPON",
        baseAtk: 0,
        refinement: 0,
        stability: 0,
        element: "NO_ELEMENT",
        modifierList: {
          id: "",
          name: "忍术卷轴·风遁术属性",
          modifiers: [
            {
              id: "",
              formula: "aspd + 300",
              belongToModifierListId: "",
            },
          ],
        },
        modifierListId: "",
        createdAt: new Date(),
        createdByUserId: "",
        updatedAt: new Date(),
        updatedByUserId: "",
        extraDetails: "",
        dataSources: "",
        statistics: defaultStatistics,
        statisticsId: "",
      },
      subWeaponId: "",
      bodyArmor: {
        id: "",
        name: "冒险者服装",
        bodyArmorType: "NORMAL",
        refinement: 0,
        baseDef: 0,
        crystalList: [
          {
            id: "",
            name: "铁之女帝",
            crystalType: "GENERAL",
            front: 0,
            modifierList: {
              id: "",
              name: "铁之女帝",
              modifiers: [
                {
                  id: "",
                  formula: "mAtk + 5%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "mPie + 10",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "cspd + 20%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "maxMp - 300",
                  belongToModifierListId: "",
                },
              ],
            },
            modifierListId: "",
            createdAt: new Date(),
            createdByUserId: "",
            updatedAt: new Date(),
            updatedByUserId: "",
            extraDetails: "",
            dataSources: "",
            statistics: defaultStatistics,
            statisticsId: "",
          },
          {
            id: "",
            name: "约尔拉兹",
            crystalType: "GENERAL",
            front: 0,
            modifierList: {
              id: "",
              name: "约尔拉兹",
              modifiers: [
                {
                  id: "",
                  formula: "mAtk + 7%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "int + 3%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "cspd + 35%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "ampr + 10%",
                  belongToModifierListId: "",
                },
              ],
            },
            modifierListId: "",
            createdAt: new Date(),
            createdByUserId: "",
            updatedAt: new Date(),
            updatedByUserId: "",
            extraDetails: "",
            dataSources: "",
            statistics: defaultStatistics,
            statisticsId: "",
          },
        ],
        modifierList: {
          id: "",
          name: "冒险者服装属性",
          modifiers: [
            {
              id: "",
              formula: "pCr + 25",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "pCd + 10%",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "pCd + 21",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "stro.DARK + 21",
              belongToModifierListId: "",
            },
          ],
        },
        modifierListId: "",
        createdAt: new Date(),
        createdByUserId: "",
        updatedAt: new Date(),
        updatedByUserId: "",
        extraDetails: "",
        dataSources: "",
        statistics: defaultStatistics,
        statisticsId: "",
      },
      bodyArmorId: "",
      additionalEquipment: {
        id: "",
        name: "饼干腰翼",
        refinement: 0,
        crystalList: [
          {
            id: "",
            name: "深谋的青影",
            crystalType: "GENERAL",
            front: 0,
            modifierList: {
              id: "",
              name: "深谋的青影",
              modifiers: [
                {
                  id: "",
                  formula: "sDis + 8%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "lDis + 8%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "maxMp - 150",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "uAtk + 8%",
                  belongToModifierListId: "",
                },
              ],
            },
            modifierListId: "",
            createdAt: new Date(),
            createdByUserId: "",
            updatedAt: new Date(),
            updatedByUserId: "",
            extraDetails: "",
            dataSources: "",
            statistics: defaultStatistics,
            statisticsId: "",
          },
          {
            id: "",
            name: "蜜爱丽",
            crystalType: "GENERAL",
            front: 0,
            modifierList: {
              id: "",
              name: "蜜爱丽属性",
              modifiers: [
                {
                  id: "",
                  formula: "",
                  belongToModifierListId: "",
                },
              ],
            },
            modifierListId: "",
            createdAt: new Date(),
            createdByUserId: "",
            updatedAt: new Date(),
            updatedByUserId: "",
            extraDetails: "",
            dataSources: "",
            statistics: defaultStatistics,
            statisticsId: "",
          },
        ],
        modifierList: {
          id: "",
          name: "饼干腰翼属性",
          modifiers: [
            {
              id: "",
              formula: "lDis + 10%",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "dex + 5%",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "mPie + isMAGIC_DEVICE(mainWeapon) ?  25 : 0",
              belongToModifierListId: "",
            },
          ],
        },
        modifierListId: "",
        createdAt: new Date(),
        createdByUserId: "",
        updatedAt: new Date(),
        updatedByUserId: "",
        extraDetails: "",
        dataSources: "",
        statistics: defaultStatistics,
        statisticsId: "",
      },
      additionalEquipmentId: "",
      specialEquipment: {
        id: "",
        name: "读星提灯",
        crystalList: [
          {
            id: "",
            name: "星之魔导士",
            crystalType: "GENERAL",
            front: 0,
            modifierList: {
              id: "",
              name: "星之魔导士",
              modifiers: [
                {
                  id: "",
                  formula: "mAtk + 9%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "cspd + 9%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "anticipate + 9%",
                  belongToModifierListId: "",
                },
              ],
            },
            modifierListId: "",
            createdAt: new Date(),
            createdByUserId: "",
            updatedAt: new Date(),
            updatedByUserId: "",
            extraDetails: "",
            dataSources: "",
            statistics: defaultStatistics,
            statisticsId: "",
          },
          {
            id: "",
            name: "塔图罗基特",
            crystalType: "GENERAL",
            front: 0,
            modifierList: {
              id: "",
              name: "塔图罗基特属性",
              modifiers: [
                {
                  id: "",
                  formula: "pAtk + 6%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "mAtk + 6%",
                  belongToModifierListId: "",
                },
                {
                  id: "",
                  formula: "am + 2",
                  belongToModifierListId: "",
                },
              ],
            },
            modifierListId: "",
            createdAt: new Date(),
            createdByUserId: "",
            updatedAt: new Date(),
            updatedByUserId: "",
            extraDetails: "",
            dataSources: "",
            statistics: defaultStatistics,
            statisticsId: "",
          },
        ],
        modifierList: {
          id: "",
          name: "读星提灯属性",
          modifiers: [
            {
              id: "",
              formula: "mPie + 10",
              belongToModifierListId: "",
            },
            {
              id: "",
              formula: "maxMp + 300",
              belongToModifierListId: "",
            },
          ],
        },
        modifierListId: "",
        createdAt: new Date(),
        createdByUserId: "",
        updatedAt: new Date(),
        updatedByUserId: "",
        extraDetails: "",
        dataSources: "",
        statistics: defaultStatistics,
        statisticsId: "",
      },
      specialEquipmentId: "",
      fashion: defaultModifierList,
      fashionModifierListId: "",
      cuisine: defaultModifierList,
      cuisineModifierListId: "",
      consumableList: [defaultConsumable],
      skillList: [defaultSkill],
      combos: [],
      pet: defaultPet,
      petId: defaultPet.id,
      modifierList: defaultModifierList,
      modifierListId: defaultModifierList.id,
      createdAt: new Date(),
      createdByUserId: "",
      updatedAt: new Date(),
      updatedByUserId: "",
      extraDetails: "",
      statistics: defaultStatistics,
      statisticsId: "",
      imageId: "",
    } satisfies Character,
    characterId: "",
    flow: [
      {
        id: "systemStart",
        componentType: "task",
        type: "message",
        name: "开始!",
        properties: { message: "开始!" },
      },
      {
        id: "systemEnd",
        componentType: "task",
        type: "message",
        name: "结束",
        properties: { message: "结束" },
      },
    ],
  },
  monster: {
    id: "",
    image: defaultImage,
    imageId: "",
    name: "岩龙菲尔岑 四星",
    monsterType: "COMMON_BOSS",
    baseLv: 251,
    experience: 0,
    address: "",
    element: "EARTH",
    radius: 2,
    maxhp: 31710000,
    physicalDefense: 6330,
    physicalResistance: 8,
    magicalDefense: 4434,
    magicalResistance: 8,
    criticalResistance: 20,
    avoidance: 1896,
    dodge: 2,
    block: 8,
    normalAttackResistanceModifier: 0,
    physicalAttackResistanceModifier: 0,
    magicalAttackResistanceModifier: 0,
    difficultyOfTank: 5,
    difficultyOfMelee: 5,
    difficultyOfRanged: 5,
    possibilityOfRunningAround: 0,
    dataSources: "",
    createdAt: new Date(),
    createdByUserId: "",
    updatedAt: new Date(),
    updatedByUserId: "",
    extraDetails: "",
    statistics: defaultStatistics,
    statisticsId: "",
  },
  skillSequence1: {
    name: "skillSequence1",
    data: [
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "神速掌握",
        skillDescription: "",
        level: 10,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillEffect: {
          id: "",
          description: "",
          actionBaseDurationFormula: "13",
          actionModifiableDurationFormula: "48",
          skillExtraActionType: "None",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "0",
          skillStartupFramesFormula: "13",
          belongToskillId: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "100",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              name: "角色行动速度+10%",
              yieldType: "ImmediateEffect",
              yieldFormula: "p.am + 10",
              mutationTimingFormula: null,
              skillEffectId: null,
            },
            {
              id: "",
              name: "角色攻速+300",
              yieldType: "ImmediateEffect",
              mutationTimingFormula: null,
              yieldFormula: "p.aspd + 300",
              skillEffectId: null,
            },
          ],
        },
      },
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "神速掌握",
        skillDescription: "",
        level: 10,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillEffect: {
          id: "",
          description: "",
          actionBaseDurationFormula: "13",
          actionModifiableDurationFormula: "48",
          skillExtraActionType: "None",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "0",
          skillStartupFramesFormula: "13",
          belongToskillId: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "100",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              name: "角色行动速度+10%",
              yieldType: "ImmediateEffect",
              yieldFormula: "p.am + 10",
              mutationTimingFormula: null,
              skillEffectId: null,
            },
            {
              id: "",
              name: "角色攻速+300",
              yieldType: "ImmediateEffect",
              mutationTimingFormula: null,
              yieldFormula: "p.aspd + 300",
              skillEffectId: null,
            },
          ],
        },
      },
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "神速掌握",
        skillDescription: "",
        level: 10,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillEffect: {
          id: "",
          description: "",
          actionBaseDurationFormula: "13",
          actionModifiableDurationFormula: "48",
          skillExtraActionType: "None",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "0",
          skillStartupFramesFormula: "13",
          belongToskillId: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "100",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              name: "角色行动速度+10%",
              yieldType: "ImmediateEffect",
              yieldFormula: "p.am + 10",
              mutationTimingFormula: null,
              skillEffectId: null,
            },
            {
              id: "",
              name: "角色攻速+300",
              yieldType: "ImmediateEffect",
              mutationTimingFormula: null,
              yieldFormula: "p.aspd + 300",
              skillEffectId: null,
            },
          ],
        },
      },
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "魔法炮充填",
        skillDescription: "",
        level: 10,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillEffect: {
          id: "",
          description: "",
          actionBaseDurationFormula: "13",
          actionModifiableDurationFormula: "48",
          skillExtraActionType: "Chanting",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "0",
          skillStartupFramesFormula: "0",
          belongToskillId: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "0",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              name: "添加魔法炮层数计数器",
              yieldType: "ImmediateEffect",
              yieldFormula: "p.mfp = 0",
              mutationTimingFormula: null,
              skillEffectId: null,
            },
            {
              id: "",
              name: "魔法炮层数自动增长行为",
              yieldType: "PersistentEffect",
              mutationTimingFormula: "frame % 60 == 0 and frame > 0",
              yieldFormula: "p.mfp + ( p.mfp >= 100 ? 1/3 : 1 )",
              skillEffectId: null,
            },
          ],
        },
      },
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "勇气源泉",
        skillDescription: "",
        level: 10,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillEffect: {
          id: "",
          description: "",
          actionBaseDurationFormula: "23",
          actionModifiableDurationFormula: "148",
          skillExtraActionType: "Chanting",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "1",
          skillStartupFramesFormula: "",
          belongToskillId: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "400",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              name: "角色最终伤害+20%",
              yieldType: "ImmediateEffect",
              yieldFormula: "p.final + 20",
              mutationTimingFormula: null,
              skillEffectId: null,
            },
            {
              id: "",
              name: "角色武器攻击+30%",
              yieldType: "ImmediateEffect",
              mutationTimingFormula: null,
              yieldFormula: "p.weaponAtk + 30%",
              skillEffectId: null,
            },
            {
              id: "",
              name: "角色命中-50%",
              yieldType: "ImmediateEffect",
              mutationTimingFormula: null,
              yieldFormula: "p.hit - 50%",
              skillEffectId: null,
            },
          ],
        },
      },
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "冲击波",
        skillDescription: "",
        level: 7,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillEffect: {
          id: "",
          description: "",
          actionBaseDurationFormula: "13",
          actionModifiableDurationFormula: "48",
          skillExtraActionType: "Chanting",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "max(0,min((2 - (p.lv - 1) * 0.25),(1 - (p.lv - 5) * 0.5)))",
          skillStartupFramesFormula: "0",
          belongToskillId: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "p.mp = p.mp - 200",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              name: "Damage",
              yieldType: "ImmediateEffect",
              yieldFormula: "m.hp - (s.vMatk + 200) * 5",
              mutationTimingFormula: null,
              skillEffectId: null,
            },
            // {
            //   id: "",
            //   name: "MP Cost half",
            //   yieldType: "PersistentEffect",
            //   yieldFormula: "",
            //   skillEffectId: null,
            //   mutationTimingFormula: "false",
            // },
          ],
        },
      },
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "爆能",
        level: 10,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillDescription: "",
        skillEffect: {
          id: "",
          actionBaseDurationFormula: "24",
          actionModifiableDurationFormula: "98",
          skillExtraActionType: "Chanting",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "8",
          skillStartupFramesFormula: "0",
          belongToskillId: "",
          description: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "p.mp = p.mp - 500",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              yieldFormula: "1+1",
              name: "Damage",
              skillEffectId: null,
              yieldType: "ImmediateEffect",
              mutationTimingFormula: null,
            },
          ],
        },
      },
    ],
  },
  skillSequence2: {
    name: "skillSequence2",
    data: [
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "冲击波",
        skillDescription: "",
        level: 7,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillEffect: {
          id: "",
          description: "",
          actionBaseDurationFormula: "13",
          actionModifiableDurationFormula: "48",
          skillExtraActionType: "Chanting",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "max(0,min((2 - (p.lv - 1) * 0.25),(1 - (p.lv - 5) * 0.5)))",
          skillStartupFramesFormula: "0",
          belongToskillId: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "p.mp = p.mp - 200",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              name: "Damage",
              yieldType: "ImmediateEffect",
              yieldFormula: "m.hp - (s.vMatk + 200) * 5",
              mutationTimingFormula: null,
              skillEffectId: null,
            },
            // {
            //   id: "",
            //   name: "MP Cost half",
            //   yieldType: "PersistentEffect",
            //   yieldFormula: "",
            //   skillEffectId: null,
            //   mutationTimingFormula: "false",
            // },
          ],
        },
      },
      {
        id: "",
        state: "PUBLIC",
        skillTreeName: "MAGIC",
        name: "爆能",
        level: 10,
        weaponElementDependencyType: "TRUE",
        element: "NO_ELEMENT",
        skillType: "ACTIVE_SKILL",
        skillDescription: "",
        skillEffect: {
          id: "",
          actionBaseDurationFormula: "24",
          actionModifiableDurationFormula: "98",
          skillExtraActionType: "Chanting",
          chargingBaseDurationFormula: "0",
          chargingModifiableDurationFormula: "0",
          chantingBaseDurationFormula: "0",
          chantingModifiableDurationFormula: "8",
          skillStartupFramesFormula: "0",
          belongToskillId: "",
          description: "",
          skillCost: [
            {
              id: "",
              name: "MP Cost",
              costFormula: "p.mp = p.mp - 500",
              skillEffectId: null,
            },
          ],
          skillYield: [
            {
              id: "",
              yieldFormula: "1+1",
              name: "Damage",
              skillEffectId: null,
              yieldType: "ImmediateEffect",
              mutationTimingFormula: null,
            },
          ],
        },
      },
    ],
  },
  analyzer: {
    id: "defaultAnalyzer",
    name: "defaultAnalyzer",
    mobs: [],
    team: [],
    extraDetails: "defaultExtraDetails",
    updatedAt: new Date(),
    updatedByUserId: "",
    createdAt: new Date(),
    createdByUserId: "",
    statistics: defaultStatistics,
    statisticsId: "",
  },
};

test.analyzer.mobs = [
  {
    id: "testMobId",
    monster: test.monster,
    monsterId: test.monster.id,
    star: 4,
    flow: "",
  },
];

test.analyzer.team = [test.member];
